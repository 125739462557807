<template>
  <div>
    <v-col>
      <v-btn @click="selectNewInvoice()">
        <template v-if="source == 'customs'">{{
          $t("NEW_CUSTOMS_DOCUMENT")
        }}</template>
        <template v-else>{{ $t("NEW_INVOICE") }}</template>
      </v-btn>
      <v-btn class="ml-5" @click="selectExistingInvoice()">
        <template v-if="source == 'customs'">{{
          $t("EXISTING_CUSTOMS_DOCUMENT")
        }}</template>
        <template v-else>{{ $t("EXISTING_INVOICE") }}</template>
      </v-btn>
    </v-col>
    <v-col>
      <template v-if="newInvoice == true">
        <p v-if="source == 'customs'">{{ $t("NEW_CUSTOMS_DOCUMENT") }}</p>
        <p v-else>{{ $t("NEW_INVOICE") }}</p>
        <ModalInvoiceNew
          :params="params"
          :type="type"
          :documentType="documentType"
          @selectedInvoice="selectedInvoice"
        ></ModalInvoiceNew>
      </template>
      <template v-if="existingInvoice == true">
        <p v-if="source == 'customs'">{{ $t("EXISTING_CUSTOMS_DOCUMENT") }}</p>
        <p v-else>{{ $t("EXISTING_INVOICE") }}</p>
        <ModalInvoiceExisting
          :source="source"
          @selectedInvoice="selectedInvoice"
        ></ModalInvoiceExisting>
      </template>
    </v-col>
  </div>
</template>
<script>
import _ from "lodash";
import ModalInvoiceNew from "@/modules/stock/components/actions/stockList/ModalInvoiceNew.vue";
import ModalInvoiceExisting from "@/modules/stock/components/actions/stockList/ModalInvoiceExisting.vue";
export default {
  name: "ModalInvoiceSelect",
  inheritAttrs: false,
  props: {
    params: {
      type: Object,
      default: function() {
        return {};
      }
    },
    type: {
      type: String,
      default: function() {
        return "incoming";
      }
    },
    documentType: {
      type: String,
      default: function() {
        return "invoice";
      }
    },
    source: {
      type: String,
      default: function() {
        return "supplier";
      }
    }
  },
  data() {
    return {
      newInvoice: false,
      existingInvoice: false
    };
  },
  components: {
    ModalInvoiceNew,
    ModalInvoiceExisting
  },
  methods: {
    selectNewInvoice() {
      this.newInvoice = true;
      this.existingInvoice = false;
      this.selectedInvoice(null);
    },
    selectExistingInvoice() {
      this.existingInvoice = true;
      this.newInvoice = false;
      this.selectedInvoice(null);
    },
    selectedInvoice(invoiceId) {
      this.$emit("selectedInvoice", invoiceId);
    }
  }
};
</script>
