<template>
  <v-card v-show="!tempIsPageLoading">
    <SearchTools
      :searchQuery.sync="searchQuery"
      @do-search="doSearch"
      @do-clear="doClear"
    >
      <template v-slot:prepend>
        <b-col :sm="3">
          <FormDatepickerBase
            :type="'text'"
            :clearable="true"
            :max="maxDate"
            :i18n="{
              label: 'FROM',
              placeholder: $t('FROM')
            }"
            :date.sync="filters.fromDate"
          ></FormDatepickerBase>
        </b-col>

        <b-col :sm="3">
          <FormDatepickerBase
            :type="'text'"
            :clearable="true"
            :max="maxDate"
            :i18n="{
              label: 'TO',
              placeholder: $t('TO')
            }"
            :date.sync="filters.toDate"
          ></FormDatepickerBase>
        </b-col>
        <b-col
          :sm="3"
          v-if="!tempIsPageLoading && _.size(stockInvoiceTypes) > 0"
        >
          <v-select
            :items="stockInvoiceTypes"
            :label="$t('STOCK_ACTION_TYPE_NAME')"
            item-text="name"
            item-value="id"
            hide-details
            clearable
            v-model="filters.stockInvoiceTypeId"
            @click:clear="clearFilter('stockInvoiceTypeId')"
          ></v-select>
        </b-col>
        <b-col
          :sm="3"
          v-if="!tempIsPageLoading && _.size(stockInvoiceTypes) > 0"
        >
          <v-select
            :items="yesNoOptions"
            :label="$t('ACCOUNTED')"
            item-text="text"
            item-value="value"
            hide-details
            clearable
            v-model="filters.isAccounted"
            @click:clear="clearFilter('isAccounted')"
          ></v-select>
        </b-col>
        <b-col
          :lg="3"
          :md="12"
          v-if="!tempIsPageLoading && _.size(suppliers) > 0"
        >
          <v-autocomplete
            hide-details
            :items="suppliers"
            :label="$t('SUPPLIERS')"
            item-text="name"
            item-value="id"
            clearable
            v-model="filters.supplierId"
            @click:clear="clearFilter('supplierId')"
          ></v-autocomplete>
        </b-col>
        <b-col
          :lg="3"
          :md="12"
          v-if="!tempIsPageLoading && _.size(forwarders) > 0"
        >
          <v-autocomplete
            hide-details
            :items="forwarders"
            :label="$t('FORWARDERS')"
            item-text="name"
            item-value="id"
            clearable
            v-model="filters.forwarderId"
            @click:clear="clearFilter('forwarderId')"
          ></v-autocomplete>
        </b-col>

        <b-col
          :lg="3"
          :md="12"
          v-if="!tempIsPageLoading && _.size(contractors) > 0"
        >
          <v-autocomplete
            hide-details
            :items="contractors"
            :label="$t('CONTRACTORS')"
            item-text="name"
            item-value="id"
            clearable
            v-model="filters.contractorId"
            @click:clear="clearFilter('contractorId')"
          ></v-autocomplete>
        </b-col>
        <b-col
          :lg="3"
          :md="12"
          v-if="!tempIsPageLoading && _.size(customs) > 0"
        >
          <v-autocomplete
            hide-details
            :items="customs"
            :label="$t('CUSTOMS')"
            item-text="name"
            item-value="id"
            clearable
            v-model="filters.customsId"
            @click:clear="clearFilter('customsId')"
          ></v-autocomplete>
        </b-col>
        <b-col
          :lg="3"
          :md="12"
          v-if="!tempIsPageLoading && _.size(clients) > 0"
        >
          <v-autocomplete
            hide-details
            :items="clients"
            :label="$t('STOCK_WHOLESALE_CLIENTS')"
            item-text="name"
            item-value="id"
            clearable
            v-model="filters.clientId"
            @click:clear="clearFilter('clientId')"
          ></v-autocomplete>
        </b-col>
        <b-col :lg="3" :md="12" v-if="!tempIsPageLoading && _.size(stores) > 0">
          <v-autocomplete
            hide-details
            v-if="_.size(stores) > 0"
            :items="stores"
            :label="$t('STORE')"
            item-text="name"
            item-value="id"
            clearable
            v-model="filters.storeId"
          ></v-autocomplete>
        </b-col>
      </template>
    </SearchTools>
    <v-data-table
      :headers="tableHeaders"
      :items="items"
      :options.sync="options"
      :items-per-page="totalItems"
      :server-items-length="totalItems"
      sort-by="issueDate"
      sort-desc="true"
      :loading="isLoadingStock"
      loading-text="Loading... Please wait"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: rowsPerPageItems,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        itemsPerPageText: $t('ITEMS_PER_PAGE_TEXT')
      }"
      @update:options="getDataFromApi()"
      class="elevation-1"
    >
      <template v-slot:item.forDate="{ item }">
        <router-link
          v-if="
            isPreviewMode == false &&
              checkIfUserHasPermission(['stock-actions-show'])
          "
          :to="{ name: 'stock-action-review', params: { id: item.id } }"
        >
          {{ item.forDate | formatDate }}</router-link
        >
        <template v-else>{{ item.forDate | formatDate }}</template>
      </template>

      <template v-slot:item.documents="{ item }">
        <v-chip
          v-if="item.stockWholesale"
          :color="item.stockWholesale.invoice_id != null ? 'green' : 'red'"
          text-color="white"
          class="ma-2"
        >
          {{ $t("WHOLESALE") }}
        </v-chip>
        <v-chip
          v-if="item.stockSupplierPurchase"
          :color="
            item.stockSupplierPurchase.invoice_id != null ? 'green' : 'red'
          "
          text-color="white"
          class="ma-2"
        >
          {{ $t("SUPPLIER") }}
        </v-chip>
        <v-chip
          v-if="item.transport"
          :color="item.transport.invoice_id != null ? 'green' : 'red'"
          text-color="white"
          class="ma-2"
        >
          {{ $t("TRANSPORT") }}
        </v-chip>
        <v-chip
          v-if="item.pallet"
          :color="item.pallet.invoice_id != null ? 'green' : 'red'"
          text-color="white"
          class="ma-2"
        >
          {{ $t("PALLET") }}
        </v-chip>
        <v-chip
          v-if="item.customs"
          :color="item.customs.invoice_id != null ? 'green' : 'red'"
          text-color="white"
          class="ma-2"
        >
          {{ $t("CUSTOMS") }}
        </v-chip>
      </template>

      <template v-slot:item.stockInvoiceTypeName="{ item }">
        <i :class="getIconOperator(item.stockInvoiceTypeOperator)"></i>

        <router-link
          v-if="
            isPreviewMode == false &&
              checkIfUserHasPermission(['stock-actions-show'])
          "
          :to="{ name: 'stock-invoice-review', params: { id: item.id } }"
        >
          {{ item.stockInvoiceTypeName }}</router-link
        >
        <template v-else> {{ item.stockInvoiceTypeName }}</template>
      </template>

      <template v-slot:item.actions="{ item }">
        <ActionButton
          v-if="isPreviewMode == false"
          :to="{ name: 'stock-invoice-review', params: { id: item.id } }"
          :permissions="['stock-actions-show']"
          :label="$t('VIEW')"
          :icon="'mdi-format-list-bulleted'"
        >
        </ActionButton>
        <v-btn
          v-if="isPreviewMode && item.regAt == null"
          @click="openInvoice(item.id)"
        >
          {{ $t("OPEN") }}
        </v-btn>
      </template>

      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="warning">
          Your search for "{{ searchQuery }}" found no results.
        </v-alert>
      </template>
    </v-data-table>
  </v-card>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";
import SearchTools from "@/core/components/table/SearchTools.vue";
import ActionButton from "@/core/components/table/ActionButton.vue";
import FormDatepickerBase from "@/view/content/forms/components/FormDatepickerBase.vue";

//Stock Module
import {
  FETCH_STOCK_INVOICES,
  FETCH_CLIENTS_WITH_STORES
} from "@/modules/stock/store/stock.module";

import { FETCH_STORES_BASIC } from "@/core/services/store/shared_store.module";
import { FETCH_SUPPLIERS_BASIC } from "@/core/services/store/shared_supplier.module";

//Shared Modules
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import i18n from "@/core/plugins/vue-i18n";

import _ from "lodash";
import moment from "moment";
export default {
  name: "StockInvoiceListTable",
  mixins: [permissionMixin],
  props: {
    isPageLoading: {
      type: Boolean,
      default: true
    },
    firstLoader: {
      type: Boolean,
      default: true
    },
    isPreviewMode: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      items: [],
      stores: [],
      suppliers: [],
      forwarders: [],
      contractors: [],
      customs: [],
      clients: [],
      totalItems: 100,
      searchQuery: "",
      filters: {
        storeId: null,
        supplierId: null,
        clientId: null,
        forwarderId: null,
        contractorId: null,
        customsId: null,
        fromDate: null,
        toDate: null,
        stockInvoiceTypeId: null,
        isAccounted: null
      },
      options: {
        descending: true,
        page: 1,
        itemsPerPage: 10,
        totalItems: 100
      },
      stockInvoiceTypes: [],
      yesNoOptions: [
        {
          value: "1",
          text: i18n.t("YES")
        },
        {
          value: "0",
          text: i18n.t("NO")
        }
      ],
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      cancelSource: null,
      selectedItemId: null,
      headers: [
        {
          text: this.$t("ID"),
          value: "id"
        },
        {
          text: this.$t("STOCK_INVOICE_TYPE_NAME"),
          value: "stockInvoiceTypeName"
        },
        {
          text: this.$t("DOCUMENT_TYPE"),
          value: "stockInvoiceDocumentTypeName"
        },
        {
          text: this.$t("FOR_DATE"),
          value: "issueDate"
        },
        {
          text: this.$t("REG_AT"),
          value: "regAt"
        },
        {
          text: this.$t("INVOICE_NUMBER"),
          value: "invoiceNumber"
        },
        {
          text: this.$t("STORE"),
          value: "storeName",
          sortable: false
        },
        {
          text: this.$t("CLIENT"),
          value: "clientName",
          sortable: false
        },
        {
          text: this.$t("SUPPLIER"),
          value: "supplierName",
          sortable: false
        },
        {
          text: this.$t("FORWARDER"),
          value: "forwarderName",
          sortable: false
        },
        {
          text: this.$t("CONTRACTOR"),
          value: "contractorName",
          sortable: false
        },
        {
          text: this.$t("CUSTOMS"),
          value: "customsName",
          sortable: false
        },
        {
          align: "right",
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ]
    };
  },
  components: {
    SearchTools,
    ActionButton,
    FormDatepickerBase
  },
  watch: {
    storesParams(value) {
      let vm = this;
      vm.filters.storeId = null;
      vm.getStores();
    },
    suppliersParams(value) {
      let vm = this;
      vm.filters.supplierId = null;
      vm.getSuppliers();
    },
    clientsParams(value) {
      let vm = this;
      vm.filters.clientId = null;
      vm.getClients();
    }
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Stock Invoices", route: { name: "stock-invoices-list" } },
      { title: "Stock Invoice List" }
    ]);

    vm.getStores();
    vm.getSuppliers();
    vm.getClients();
    vm.getSelects();
  },
  computed: {
    ...mapGetters([
      "getItems",
      "isLoadingStock",
      "isLoadingSharedStore",
      "currentUser"
    ]),
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempIsPageLoading: {
      get: function() {
        return this.isPageLoading;
      },
      set: function(newValue) {
        this.$emit("update:isPageLoading", newValue);
      }
    },
    params() {
      return {
        ...this.options,
        query: this.searchQuery,
        supplierId: this.filters.supplierId,
        forwarderId: this.filters.forwarderId,
        contractorId: this.filters.contractorId,
        customsId: this.filters.customsId,
        clientId: this.filters.clientId,
        fromDate: this.filters.fromDate,
        storeId: this.filters.storeId,
        toDate: this.filters.toDate,
        stockInvoiceTypeId: this.filters.stockInvoiceTypeId,
        isAccounted: this.filters.isAccounted
      };
    },
    storesParams() {
      let storesParams = {};
      return storesParams;
    },
    suppliersParams() {
      let suppliersParams = {};
      return suppliersParams;
    },
    clientsParams() {
      let clientsParams = {};
      return clientsParams;
    },
    tableHeaders() {
      let vm = this;

      return vm.headers;
    },
    maxDate() {
      return moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    clearFilter(key) {
      let vm = this;
      vm.$nextTick(function() {
        this.$set(vm.filters, key, null);
      });
    },
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      vm.filters.storeId = null;
      vm.filters.supplierId = null;
      vm.filters.forwarderId = null;
      vm.filters.contractorId = null;
      vm.filters.customsId = null;
      vm.filters.clientId = null;
      vm.filters.stockInvoiceTypeId = null;
      vm.filters.isAccounted = null;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      //copy current params to modify
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      vm.$store
        .dispatch(FETCH_STOCK_INVOICES, apiParams)
        .then(data => {
          vm.tempFirstLoader = false;
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;
            if (vm.tempIsPageLoading == true) {
              vm.tempIsPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "stock-invoices-list" });
          }
        });
    },
    getStores() {
      let vm = this;
      let params = this.storesParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_STORES_BASIC, apiParams)
        .then(data => {
          this.$set(vm, "stores", data.data);
        })
        .catch(response => {
          console.log(response);
        });
    },
    getSuppliers() {
      let vm = this;
      let params = this.suppliersParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_SUPPLIERS_BASIC, apiParams)
        .then(data => {
          this.$set(vm, "suppliers", data.data);
        })
        .catch(response => {
          console.log(response);
        });
    },
    getClients() {
      let vm = this;
      let params = this.clientsParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_CLIENTS_WITH_STORES, apiParams)
        .then(data => {
          this.$set(vm, "clients", data.data);
        })
        .catch(response => {
          console.log(response);
        });
    },
    getSelects() {
      let vm = this;
      let payload = {
        stock_invoice_types: {
          fields: ["id", "name"],
          search: ""
        },
        forwarders: {
          fields: ["id", "name"],
          search: ""
        },
        contractors: {
          fields: ["id", "name"],
          search: ""
        },
        customs: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.stockInvoiceTypes = data.data.stock_invoice_types;
          vm.forwarders = data.data.forwarders;
          vm.contractors = data.data.contractors;
          vm.customs = data.data.customs;
        })
        .catch(response => {
          console.log(response);
        });
    },
    getIconOperator(operator) {
      if (operator == "+") {
        return "flaticon2-plus text-success";
      }

      if (operator == "-") {
        return "flaticon2-line text-danger";
      }
      return "";
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    exportStockRedirectingXls(data) {
      console.log(data);
      console.log(this.stockInvoiceTypeId);
    },
    openInvoice(id) {
      this.$emit("openInvoice", id);
    }
  }
};
</script>
