<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          :disabled="type == 'outgoing'"
          v-model="invoice.invoiceNumber"
          :label="$t('NUMBER')"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col>
        <FormDatepickerBase
          :type="'text'"
          :clearable="true"
          :max="maxDate"
          :i18n="{
            label: 'ISSUE_DATE',
            placeholder: $t('ISSUE_DATE')
          }"
          :date.sync="invoice.issueDate"
        ></FormDatepickerBase>
      </v-col>
      <v-col>
        <v-btn
          v-if="!isPreviewMode"
          @click="create()"
          color="success"
          x-large
          block
        >
          {{ $t("CREATE") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import FormDatepickerBase from "@/view/content/forms/components/FormDatepickerBase.vue";
import { CREATE_NEW_INVOICE } from "@/modules/stock/store/stock.module";
export default {
  name: "ModalInvoiceNew",
  inheritAttrs: false,
  props: {
    params: {
      type: Object,
      default: function() {
        return {};
      }
    },
    type: {
      type: String,
      default: function() {
        return "incoming";
      }
    },
    documentType: {
      type: String,
      default: function() {
        return "invoice";
      }
    }
  },
  data() {
    return {
      invoice: {
        invoiceNumber: "",
        issueDate: ""
      }
    };
  },
  components: {
    FormDatepickerBase
  },
  computed: {
    maxDate() {
      return moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    create() {
      let vm = this;
      const payload = {
        invoiceNumber: vm.invoice.invoiceNumber,
        issueDate: vm.invoice.issueDate,
        params: vm.params
      };
      this.$store
        .dispatch(CREATE_NEW_INVOICE, payload)
        .then(data => {
          vm.errors = data.data.errors;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          let invoiceId = data.data.id;
          vm.$emit("selectedInvoice", invoiceId);
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    }
  }
};
</script>
