<template>
  <div>
    <v-btn v-if="!showProducts" @click="showProducts = !showProducts">
      {{ $t("SHOW") }} {{ $t("PRODUCTS") }}
    </v-btn>
    <v-btn v-if="showProducts" @click="showProducts = !showProducts">
      {{ $t("HIDE") }} {{ $t("PRODUCTS") }}
    </v-btn>
    <template v-if="showProducts">
      <div v-for="(action, index) in actions" v-bind:key="'item-' + index">
        <div class="mt-5 mb-5">
          <v-data-table
            :headers="headers"
            :items="action.items"
            item-key="name"
            class="elevation-1"
            :search="search"
            hide-default-footer
          >
            <template v-slot:item.unitValue="{ item }">
              {{ item.unitValue }}
              {{ item.unitShortName }}
            </template>
            <template v-slot:item.currencyCode="{ item }">
              {{ action.stockDocData.currencyCode }}
            </template>
            <template v-slot:body.append>
              <tr>
                <td colspan="3">
                  {{ $t("DOC_NUM") }}: {{ action.stockDocData.docNum }} ({{
                    $t("DOC_DATE")
                  }}: {{ action.stockDocData.docDate }})
                </td>
                <td class="text-right">
                  <strong
                    >{{ $t("TOTAL_CURRENCY") }}:
                    {{
                      action.stockDocData.docTotalCurrency | formatBalance
                    }}</strong
                  >
                </td>
                <td>
                  <strong>{{ action.stockDocData.currencyCode }}</strong>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  name: "ModalInvoiceAddItems",
  inheritAttrs: false,
  props: {
    actions: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      showProducts: false
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("ITEM_NAME"),
          align: "start",
          value: "name"
        },
        {
          text: this.$t("UNIT_VALUE"),
          value: "unitValue"
        },
        {
          text: this.$t("SINGLE_PRICE_CURRENCY"),
          align: "end",
          value: "singlePriceCurrency"
        },
        {
          text: this.$t("TOTAL_PRICE_CURRENCY"),
          align: "end",
          value: "totalPriceCurrency"
        },
        {
          text: this.$t("CURRENCY_CODE"),
          value: "currencyCode"
        }
      ];
    }
  }
};
</script>
