<template>
  <div>
    <v-card
      v-for="(forwarder, forwarderIndex) in tempForwarders"
      v-bind:key="'forwarder-' + forwarderIndex"
      class="mt-5"
    >
      <v-card-actions>
        <h1 class="ml-3">
          {{ $t("FORWARDER") }}: {{ forwarder.forwarderName }}
        </h1>
        <v-spacer></v-spacer>
        <ModalAddToInvoice
          :forwarder="forwarder"
          :type="type"
          :documentType="documentType"
          :source="source"
          @addedToInvoice="addedToInvoice($event, forwarder.forwarderId)"
        ></ModalAddToInvoice>
      </v-card-actions>
      <div>
        <TableActionsForInvoice
          v-if="_.size(forwarder.transports) > 0"
          :actions="forwarder.transports"
          :header="$t('TRANSPORTS')"
        ></TableActionsForInvoice>
      </div>
    </v-card>
  </div>
</template>
<script>
import ModalAddToInvoice from "@/modules/stock/components/actions/stockList/ModalAddToInvoice.vue";
import TableActionsForInvoice from "@/modules/stock/components/actions/stockList/TableActionsForInvoice.vue";
import _ from "lodash";
export default {
  name: "WrapperForwardersForInvoice",
  inheritAttrs: false,
  props: {
    forwarders: {
      type: Array,
      default: function() {
        return [];
      }
    },
    type: {
      type: String,
      default: function() {
        return "incoming";
      }
    },
    documentType: {
      type: String,
      default: function() {
        return "invoice";
      }
    }
  },
  data() {
    return {
      source: "forwarder",
      search: "",
      docTotalWithVatCurrency: ""
    };
  },
  components: {
    ModalAddToInvoice,
    TableActionsForInvoice
  },
  computed: {
    tempForwarders: {
      get: function() {
        return this.forwarders;
      },
      set: function(newValue) {
        this.$emit("update:forwarders", newValue);
      }
    }
  },
  methods: {
    addedToInvoice(params, forwarderId) {
      let args = {
        ...params,
        forwarderId: forwarderId
      };
      this.$emit("addedToInvoice", args);
    }
  }
};
</script>
