<template>
  <div>
    <v-card
      v-for="(contractor, contractorIndex) in tempContractors"
      v-bind:key="'contractor-' + contractorIndex"
      class="mt-5"
    >
      <v-card-actions>
        <h1 class="ml-3">
          {{ $t("CONTRACTOR") }}: {{ contractor.contractorName }}
        </h1>
        <v-spacer></v-spacer>
        <ModalAddToInvoice
          :contractor="contractor"
          :type="type"
          :documentType="documentType"
          :source="source"
          @addedToInvoice="addedToInvoice($event, contractor.contractorId)"
        ></ModalAddToInvoice>
      </v-card-actions>
      <div>
        <TableActionsForInvoice
          v-if="_.size(contractor.addons) > 0"
          :actions="contractor.addons"
          :header="$t('ADDONS')"
        ></TableActionsForInvoice>
      </div>
    </v-card>
  </div>
</template>
<script>
import ModalAddToInvoice from "@/modules/stock/components/actions/stockList/ModalAddToInvoice.vue";
import TableActionsForInvoice from "@/modules/stock/components/actions/stockList/TableActionsForInvoice.vue";
import _ from "lodash";
export default {
  name: "WrapperContractorsForInvoice",
  inheritAttrs: false,
  props: {
    contractors: {
      type: Array,
      default: function() {
        return [];
      }
    },
    type: {
      type: String,
      default: function() {
        return "incoming";
      }
    },
    documentType: {
      type: String,
      default: function() {
        return "invoice";
      }
    }
  },
  data() {
    return {
      source: "contractor",
      search: "",
      docTotalWithVatCurrency: ""
    };
  },
  components: {
    ModalAddToInvoice,
    TableActionsForInvoice
  },
  computed: {
    tempContractors: {
      get: function() {
        return this.contractors;
      },
      set: function(newValue) {
        this.$emit("update:contractors", newValue);
      }
    }
  },
  methods: {
    addedToInvoice(params, contractorId) {
      let args = {
        ...params,
        contractorId: contractorId
      };
      this.$emit("addedToInvoice", args);
    }
  }
};
</script>
