<template>
  <div>
    <v-card
      v-for="(supplier, supplierIndex) in tempSuppliers"
      v-bind:key="'supplier-' + supplierIndex"
      class="mt-5"
    >
      <v-card-actions>
        <h1 class="ml-3">{{ $t("SUPPLIER") }}: {{ supplier.supplierName }}</h1>
        <v-spacer></v-spacer>
        <ModalAddToInvoice
          :supplier="supplier"
          :type="type"
          :documentType="documentType"
          :source="source"
          @addedToInvoice="addedToInvoice($event, supplier.supplierId)"
        ></ModalAddToInvoice>
      </v-card-actions>
      <div>
        <TableActionsForInvoice
          v-if="_.size(supplier.purchases) > 0"
          :actions="supplier.purchases"
          :header="$t('PURCHASES')"
        ></TableActionsForInvoice>
        <TableActionsForInvoice
          v-if="_.size(supplier.returnings) > 0"
          :actions="supplier.returnings"
          :header="$t('RETURNINGS')"
        ></TableActionsForInvoice>
        <TableActionsForInvoice
          v-if="_.size(supplier.pallets) > 0"
          :actions="supplier.pallets"
          :header="$t('PALLETS')"
        ></TableActionsForInvoice>
        <TableActionsForInvoice
          v-if="_.size(supplier.transports) > 0"
          :actions="supplier.transports"
          :header="$t('TRANSPORTS')"
        ></TableActionsForInvoice>
        <TableActionsForInvoice
          v-if="_.size(supplier.addons) > 0"
          :actions="supplier.addons"
          :header="$t('ADDONS')"
        ></TableActionsForInvoice>
      </div>
    </v-card>
  </div>
</template>
<script>
import ModalAddToInvoice from "@/modules/stock/components/actions/stockList/ModalAddToInvoice.vue";
import TableActionsForInvoice from "@/modules/stock/components/actions/stockList/TableActionsForInvoice.vue";
import _ from "lodash";
export default {
  name: "WrapperSuppliersForInvoice",
  inheritAttrs: false,
  props: {
    suppliers: {
      type: Array,
      default: function() {
        return [];
      }
    },
    type: {
      type: String,
      default: function() {
        return "incoming";
      }
    },
    documentType: {
      type: String,
      default: function() {
        return "invoice";
      }
    }
  },
  data() {
    return {
      source: "supplier",
      search: "",
      docTotalWithVatCurrency: ""
    };
  },
  components: {
    ModalAddToInvoice,
    TableActionsForInvoice
  },
  computed: {
    tempSuppliers: {
      get: function() {
        return this.suppliers;
      },
      set: function(newValue) {
        this.$emit("update:suppliers", newValue);
      }
    }
  },
  methods: {
    addedToInvoice(params, supplierId) {
      let args = {
        ...params,
        supplierId: supplierId
      };
      this.$emit("addedToInvoice", args);
    }
  }
};
</script>
