<template>
  <div>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("MOVEMENTS") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <router-link
            v-permission="['stock-actions-create']"
            to="receiving"
            class="btn btn-light-success font-weight-bolder mr-2"
            ><i class="flaticon2-plus"></i>
            {{ $t("STOCK_RECEIVING") }}</router-link
          >
          <router-link
            v-permission="['stock-actions-create']"
            to="returning"
            class="btn btn-light-success font-weight-bolder mr-2"
            ><i class="flaticon2-plus"></i>
            {{ $t("STOCK_RETURNING") }}</router-link
          >
          <router-link
            v-permission="['stock-actions-create']"
            to="scrapping"
            class="btn btn-light-success font-weight-bolder mr-2"
            ><i class="flaticon2-plus"></i>
            {{ $t("STOCK_SCRAPPING") }}</router-link
          >
          <router-link
            v-permission="['stock-actions-create']"
            to="testing"
            class="btn btn-light-success font-weight-bolder mr-2"
            ><i class="flaticon2-plus"></i>
            {{ $t("STOCK_TESTING") }}</router-link
          >
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
        >
          <template v-slot:prepend>
            <b-col :lg="3" :md="12" v-if="!isPageLoading && _.size(stores) > 0">
              <v-autocomplete
                v-if="_.size(stores) > 0"
                :items="stores"
                :label="$t('STORE')"
                item-text="name"
                item-value="id"
                clearable
                hide-details
                v-model="filters.storeId"
                @click:clear="clearFilter('storeId')"
              ></v-autocomplete>
            </b-col>
            <b-col
              :sm="3"
              v-if="!isPageLoading && _.size(stockActionTypes) > 0"
            >
              <v-autocomplete
                v-if="_.size(stockActionTypes) > 0"
                :items="stockActionTypes"
                :label="$t('STOCK_ACTION_TYPE_NAME')"
                item-text="name"
                item-value="id"
                clearable
                hide-details
                v-model="filters.stockActionTypeId"
                @click:clear="clearFilter('stockActionTypeId')"
              ></v-autocomplete>
            </b-col>
            <b-col :sm="3">
              <FormDatepickerBase
                :type="'text'"
                :clearable="true"
                :max="maxDate"
                :i18n="{
                  label: 'FROM',
                  placeholder: $t('FROM')
                }"
                :date.sync="filters.fromDate"
              ></FormDatepickerBase>
            </b-col>

            <b-col :sm="3">
              <FormDatepickerBase
                :type="'text'"
                :clearable="true"
                :max="maxDate"
                :i18n="{
                  label: 'TO',
                  placeholder: $t('TO')
                }"
                :date.sync="filters.toDate"
              ></FormDatepickerBase>
            </b-col>
            <b-col :sm="3">
              <v-select
                :items="yesNoOptions"
                :label="$t('HAS_SUPPLIER')"
                item-text="text"
                item-value="value"
                hide-details
                clearable
                v-model="filters.hasSupplier"
                @click:clear="clearFilter('hasSupplier')"
              ></v-select>
            </b-col>
            <b-col :sm="3">
              <v-select
                :items="yesNoOptions"
                :label="$t('HAS_INVOICE_SUPPLIER')"
                item-text="text"
                item-value="value"
                hide-details
                clearable
                v-model="filters.hasInvoiceSupplier"
                @click:clear="clearFilter('hasInvoiceSupplier')"
              ></v-select>
            </b-col>
            <b-col :md="3">
              <v-text-field
                v-model="filters.supplierDocNum"
                :label="$t('SUPPLIER_DOC_NUM')"
                single-line
                hide-details
              ></v-text-field>
            </b-col>
            <b-col :md="3">
              <FormDatepickerBase
                :type="'text'"
                :clearable="true"
                :max="maxDate"
                :i18n="{
                  label: 'SUPPLIER_DOC_DATE',
                  placeholder: $t('SUPPLIER_DOC_DATE')
                }"
                :date.sync="filters.supplierDocDate"
              ></FormDatepickerBase>
            </b-col>

            <b-col :sm="3">
              <v-select
                :items="yesNoOptions"
                :label="$t('HAS_PALLET')"
                item-text="text"
                item-value="value"
                hide-details
                clearable
                v-model="filters.hasPallet"
                @click:clear="clearFilter('hasPallet')"
              ></v-select>
            </b-col>
            <b-col :sm="3">
              <v-select
                :items="yesNoOptions"
                :label="$t('HAS_INVOICE_PALLET')"
                item-text="text"
                item-value="value"
                hide-details
                clearable
                v-model="filters.hasInvoicePallet"
                @click:clear="clearFilter('hasInvoicePallet')"
              ></v-select>
            </b-col>
            <b-col :md="3">
              <v-text-field
                v-model="filters.palletDocNum"
                :label="$t('PALLET_DOC_NUM')"
                single-line
                hide-details
              ></v-text-field>
            </b-col>
            <b-col :md="3">
              <FormDatepickerBase
                :type="'text'"
                :clearable="true"
                :max="maxDate"
                :i18n="{
                  label: 'PALLET_DOC_DATE',
                  placeholder: $t('PALLET_DOC_DATE')
                }"
                :date.sync="filters.palletDocDate"
              ></FormDatepickerBase>
            </b-col>
            <b-col :sm="3">
              <v-select
                :items="yesNoOptions"
                :label="$t('HAS_TRANSPORT')"
                item-text="text"
                item-value="value"
                hide-details
                clearable
                v-model="filters.hasTransport"
                @click:clear="clearFilter('hasTransport')"
              ></v-select>
            </b-col>
            <b-col :sm="3">
              <v-select
                :items="yesNoOptions"
                :label="$t('HAS_INVOICE_TRANSPORT')"
                item-text="text"
                item-value="value"
                hide-details
                clearable
                v-model="filters.hasInvoiceTransport"
                @click:clear="clearFilter('hasInvoiceTransport')"
              ></v-select>
            </b-col>
            <b-col :md="3">
              <v-text-field
                v-model="filters.transportDocNum"
                :label="$t('TRANSPORT_DOC_NUM')"
                single-line
                hide-details
              ></v-text-field>
            </b-col>
            <b-col :md="3">
              <FormDatepickerBase
                :type="'text'"
                :clearable="true"
                :max="maxDate"
                :i18n="{
                  label: 'TRANSPORT_DOC_DATE',
                  placeholder: $t('TRANSPORT_DOC_DATE')
                }"
                :date.sync="filters.transportDocDate"
              ></FormDatepickerBase>
            </b-col>
            <b-col :sm="3">
              <v-select
                :items="yesNoOptions"
                :label="$t('HAS_ADDON')"
                item-text="text"
                item-value="value"
                hide-details
                clearable
                v-model="filters.hasAddon"
                @click:clear="clearFilter('hasAddon')"
              ></v-select>
            </b-col>
            <b-col :sm="3">
              <v-select
                :items="yesNoOptions"
                :label="$t('HAS_INVOICE_ADDON')"
                item-text="text"
                item-value="value"
                hide-details
                clearable
                v-model="filters.hasInvoiceAddon"
                @click:clear="clearFilter('hasInvoiceAddon')"
              ></v-select>
            </b-col>
            <b-col :md="3">
              <v-text-field
                v-model="filters.addonDocNum"
                :label="$t('ADDON_DOC_NUM')"
                single-line
                hide-details
              ></v-text-field>
            </b-col>
            <b-col :md="3">
              <FormDatepickerBase
                :type="'text'"
                :clearable="true"
                :max="maxDate"
                :i18n="{
                  label: 'ADDON_DOC_DATE',
                  placeholder: $t('ADDON_DOC_DATE')
                }"
                :date.sync="filters.addonDocDate"
              ></FormDatepickerBase>
            </b-col>
            <b-col :sm="3">
              <v-select
                :items="yesNoOptions"
                :label="$t('HAS_CUSTOMS')"
                item-text="text"
                item-value="value"
                hide-details
                clearable
                v-model="filters.hasCustoms"
                @click:clear="clearFilter('hasCustoms')"
              ></v-select>
            </b-col>
            <b-col :sm="3">
              <v-select
                :items="yesNoOptions"
                :label="$t('HAS_INVOICE_CUSTOMS')"
                item-text="text"
                item-value="value"
                hide-details
                clearable
                v-model="filters.hasInvoiceCustoms"
                @click:clear="clearFilter('hasInvoiceCustoms')"
              ></v-select>
            </b-col>
            <b-col :md="3">
              <v-text-field
                v-model="filters.customsDocNum"
                :label="$t('CUSTOMS_DOC_NUM')"
                single-line
                hide-details
              ></v-text-field>
            </b-col>
            <b-col :md="3">
              <FormDatepickerBase
                :type="'text'"
                :clearable="true"
                :max="maxDate"
                :i18n="{
                  label: 'CUSTOMS_DOC_DATE',
                  placeholder: $t('CUSTOMS_DOC_DATE')
                }"
                :date.sync="filters.customsDocDate"
              ></FormDatepickerBase>
            </b-col>

            <b-col :sm="3">
              <v-select
                :items="yesNoOptions"
                :label="$t('HAS_WHOLESALE')"
                item-text="text"
                item-value="value"
                hide-details
                clearable
                v-model="filters.hasWholesale"
                @click:clear="clearFilter('hasWholesale')"
              ></v-select>
            </b-col>
            <b-col :sm="3">
              <v-select
                :items="yesNoOptions"
                :label="$t('HAS_INVOICE_WHOLESALE')"
                item-text="text"
                item-value="value"
                hide-details
                clearable
                v-model="filters.hasInvoiceWholesale"
                @click:clear="clearFilter('hasInvoiceWholesale')"
              ></v-select>
            </b-col>
            <b-col :md="3">
              <v-text-field
                v-model="filters.wholesaleDocNum"
                :label="$t('WHOLESALE_DOC_NUM')"
                single-line
                hide-details
              ></v-text-field>
            </b-col>
            <b-col :md="3">
              <FormDatepickerBase
                :type="'text'"
                :clearable="true"
                :max="maxDate"
                :i18n="{
                  label: 'WHOLESALE_DOC_DATE',
                  placeholder: $t('WHOLESALE_DOC_DATE')
                }"
                :date.sync="filters.wholesaleDocDate"
              ></FormDatepickerBase>
            </b-col>
            <b-col
              :lg="3"
              :md="12"
              v-if="!isPageLoading && _.size(suppliers) > 0"
            >
              <v-autocomplete
                v-if="_.size(suppliers) > 0"
                :items="suppliers"
                :label="$t('SUPPLIERS')"
                item-text="name"
                item-value="id"
                clearable
                hide-details
                v-model="filters.supplierId"
                @click:clear="clearFilter('supplierId')"
              ></v-autocomplete>
            </b-col>
            <b-col
              :lg="3"
              :md="12"
              v-if="!isPageLoading && _.size(clients) > 0"
            >
              <v-autocomplete
                v-if="_.size(clients) > 0"
                :items="clients"
                :label="$t('STOCK_WHOLESALE_CLIENTS')"
                item-text="name"
                item-value="id"
                clearable
                hide-details
                v-model="filters.clientId"
                @click:clear="clearFilter('clientId')"
              ></v-autocomplete>
            </b-col>

            <b-col :lg="3" :md="12">
              <v-checkbox
                :label="$t('ECHO_ERROR')"
                v-model="filters.echoError"
              ></v-checkbox>
            </b-col>

            <b-col :lg="3" :md="12">
              <v-checkbox
                :label="$t('AJUR_ERROR')"
                v-model="filters.ajurError"
              ></v-checkbox>
            </b-col>
          </template>
        </SearchTools>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :options.sync="filters.options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          sort-by="forDate"
          sort-desc="true"
          :loading="isLoadingStock"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            itemsPerPageText: $t('ITEMS_PER_PAGE_TEXT')
          }"
          @update:options="getDataFromApi()"
          class="elevation-1"
        >
          <template v-slot:item.forDate="{ item }">
            <router-link
              v-if="checkIfUserHasPermission(['stock-actions-show'])"
              :to="{ name: 'stock-action-review', params: { id: item.id } }"
            >
              {{ item.forDate | formatDate }}</router-link
            >
            <template v-else>{{ item.forDate | formatDate }}</template>
          </template>

          <template v-slot:item.documents="{ item }">
            <v-row no-gutters v-if="item.stockActionId == null">
              <v-checkbox
                v-if="item.stockWholesale && item.stockActionTypeId != 13"
                class="mr-2"
                v-model="selectedForInvoice.wholesales"
                :label="$t('WHOLESALE')"
                :color="item.stockWholesale.invoiceId != null ? 'red' : 'green'"
                :value="item.stockWholesale"
                :disabled="item.stockWholesale.invoiceId != null"
                :indeterminate="item.stockWholesale.invoiceId != null"
                hide-details
              ></v-checkbox>

              <v-checkbox
                v-if="item.stockWholesale && item.stockActionTypeId == 13"
                class="mr-2"
                v-model="selectedForInvoice.wholesaleReturnings"
                :label="$t('WHOLESALE')"
                :color="item.stockWholesale.invoiceId != null ? 'red' : 'green'"
                :value="item.stockWholesale"
                :disabled="item.stockWholesale.invoiceId != null"
                :indeterminate="item.stockWholesale.invoiceId != null"
                hide-details
              ></v-checkbox>

              <v-checkbox
                v-if="item.stockSupplierPurchase && item.stockActionTypeId != 3"
                class="mr-2"
                v-model="selectedForInvoice.suppliers"
                :label="$t('SUPPLIER')"
                :color="
                  item.stockSupplierPurchase.invoiceId != null ? 'red' : 'green'
                "
                :value="item.stockSupplierPurchase"
                :disabled="item.stockSupplierPurchase.invoiceId != null"
                :indeterminate="item.stockSupplierPurchase.invoiceId != null"
                hide-details
              ></v-checkbox>

              <v-checkbox
                v-if="item.stockSupplierPurchase && item.stockActionTypeId == 3"
                class="mr-2"
                v-model="selectedForInvoice.returnings"
                :label="$t('SUPPLIER')"
                :color="
                  item.stockSupplierPurchase.invoiceId != null ? 'red' : 'green'
                "
                :value="item.stockSupplierPurchase"
                :disabled="item.stockSupplierPurchase.invoiceId != null"
                :indeterminate="item.stockSupplierPurchase.invoiceId != null"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-if="item.pallet"
                class="mr-2"
                v-model="selectedForInvoice.pallets"
                :label="$t('PALLET')"
                :color="item.pallet.invoiceId != null ? 'red' : 'green'"
                :value="item.pallet"
                :disabled="item.pallet.invoiceId != null"
                :indeterminate="item.pallet.invoiceId != null"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-if="item.transport"
                class="mr-2"
                v-model="selectedForInvoice.transports"
                :label="$t('TRANSPORT')"
                :color="item.transport.invoiceId != null ? 'red' : 'green'"
                :value="item.transport"
                :disabled="item.transport.invoiceId != null"
                :indeterminate="item.transport.invoiceId != null"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-if="item.addon"
                class="mr-2"
                v-model="selectedForInvoice.addons"
                :label="$t('ADDON')"
                :color="item.addon.invoiceId != null ? 'red' : 'green'"
                :value="item.addon"
                :disabled="item.addon.invoiceId != null"
                :indeterminate="item.addon.invoiceId != null"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-if="item.customs"
                class="mr-2"
                v-model="selectedForInvoice.customs"
                :label="$t('CUSTOMS')"
                :color="item.customs.invoiceId != null ? 'red' : 'green'"
                :value="item.customs"
                :disabled="item.customs.invoiceId != null"
                :indeterminate="item.customs.invoiceId != null"
                hide-details
              ></v-checkbox>
            </v-row>
          </template>

          <template v-slot:item.stockActionTypeName="{ item }">
            <i :class="getIconOperator(item.stockActionTypeOperator)"></i>

            <router-link
              v-if="checkIfUserHasPermission(['stock-actions-show'])"
              :to="{ name: 'stock-action-review', params: { id: item.id } }"
            >
              {{ item.stockActionTypeName }}</router-link
            >
            <template v-else>{{ item.stockActionTypeName }}</template>
          </template>

          <template v-slot:item.supplierName="{ item }">
            {{
              item.stockSupplierPurchase
                ? item.stockSupplierPurchase.supplierName
                : ""
            }}
            {{ item.stockWholesale ? item.stockWholesale.clientName : "" }}

            <router-link
              v-if="item.stockActionTypeId == 2 && item.stockPurchaseOrder"
              :to="{
                name: 'stock-purchase-order-review',
                params: { id: item.stockPurchaseOrder.id }
              }"
            >
              ({{ item.stockPurchaseOrder.id }})
              {{ item.stockPurchaseOrder.toStoreName }}</router-link
            >
            <router-link
              v-if="item.stockActionTypeId == 4 && item.stockPurchaseOrder"
              :to="{
                name: 'stock-purchase-order-review',
                params: { id: item.stockPurchaseOrder.id }
              }"
            >
              ({{ item.stockPurchaseOrder.id }})
              {{ item.stockPurchaseOrder.fromStoreName }}</router-link
            >
            <router-link
              v-if="item.stockActionTypeId == 9 && item.stockPurchaseOrder"
              :to="{
                name: 'stock-purchase-order-review',
                params: { id: item.stockPurchaseOrder.id }
              }"
            >
              ({{ item.stockPurchaseOrder.id }})

              {{ item.stockPurchaseOrder.toStoreName }}
            </router-link>
            <router-link
              v-if="item.stockActionTypeId == 16 && item.stockPurchaseOrder"
              :to="{
                name: 'stock-purchase-order-review',
                params: { id: item.stockPurchaseOrder.id }
              }"
            >
              ({{ item.stockPurchaseOrder.id }})

              {{ item.stockPurchaseOrder.fromStoreName }}
            </router-link>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex justify-content-end">
              <div class="mr-3">
                <v-tooltip
                  v-if="
                    item.forEcho == true &&
                      item.echoAt == null &&
                      item.echoQueueAt == null
                  "
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="red"
                      >mdi-alert</v-icon
                    >
                  </template>
                  <span>Проблем с Echo</span>
                </v-tooltip>
              </div>
              <div class="mr-3">
                <v-tooltip
                  v-if="
                    (item.forEcho == true &&
                      item.echoAt != null &&
                      item.forAjur == true &&
                      item.ajurAt == null) ||
                      (item.forEcho == false &&
                        item.forAjur == true &&
                        item.ajurAt == null)
                  "
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="orange"
                      >mdi-alert</v-icon
                    >
                  </template>
                  <span>Проблем с Ajur</span>
                </v-tooltip>
              </div>
              <ActionButton
                :to="{ name: 'stock-action-review', params: { id: item.id } }"
                :permissions="['stock-actions-show']"
                :label="$t('VIEW')"
                :icon="'mdi-format-list-bulleted'"
              />
            </div>
          </template>
          <template slot="body.append">
            <tr class="previewTr">
              <th colspan="100%">
                {{ $t("TOTAL_VALUE") }}:
                {{ allItemsTotalPriceSum | formatBalance }}
              </th>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
      <WrapperClientsForInvoice
        v-if="_.size(selectedForInvoiceTransformed.clients) > 0"
        :clients.sync="selectedForInvoiceTransformed.clients"
        :type="'outgoing'"
        :documentType="'invoice'"
        @addedToInvoice="addedToInvoiceClient"
      ></WrapperClientsForInvoice>
      <WrapperClientsForInvoice
        v-if="_.size(selectedForInvoiceTransformed.wholesaleReturnings) > 0"
        :clients.sync="selectedForInvoiceTransformed.wholesaleReturnings"
        :type="'outgoing'"
        :documentType="'credit'"
        @addedToInvoice="addedToInvoiceClientReturning"
      ></WrapperClientsForInvoice>
      <WrapperSuppliersForInvoice
        v-if="_.size(selectedForInvoiceTransformed.suppliers) > 0"
        :suppliers.sync="selectedForInvoiceTransformed.suppliers"
        :type="'incoming'"
        :documentType="'invoice'"
        @addedToInvoice="addedToInvoiceSupplier"
      ></WrapperSuppliersForInvoice>
      <WrapperSuppliersForInvoice
        v-if="_.size(selectedForInvoiceTransformed.returnings) > 0"
        :suppliers.sync="selectedForInvoiceTransformed.returnings"
        :type="'incoming'"
        :documentType="'credit'"
        @addedToInvoice="addedToInvoiceReturning"
      ></WrapperSuppliersForInvoice>
      <WrapperForwardersForInvoice
        v-if="_.size(selectedForInvoiceTransformed.forwarders) > 0"
        :forwarders.sync="selectedForInvoiceTransformed.forwarders"
        :type="'incoming'"
        :documentType="'invoice'"
        @addedToInvoice="addedToInvoiceForwarder"
      ></WrapperForwardersForInvoice>
      <WrapperContractorsForInvoice
        v-if="_.size(selectedForInvoiceTransformed.contractors) > 0"
        :contractors.sync="selectedForInvoiceTransformed.contractors"
        :type="'incoming'"
        :documentType="'invoice'"
        @addedToInvoice="addedToInvoiceContractor"
      ></WrapperContractorsForInvoice>

      <WrapperCustomsForInvoice
        v-if="_.size(selectedForInvoiceTransformed.customs) > 0"
        :customs.sync="selectedForInvoiceTransformed.customs"
        :type="'incoming'"
        :documentType="'customs'"
        @addedToInvoice="addedToInvoiceCustoms"
      ></WrapperCustomsForInvoice>
      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
      <v-overlay :value="!firstLoader && isLoadingSharedStore">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <template v-slot:footer></template>
    </b-card>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";
import SearchTools from "@/core/components/table/SearchTools.vue";
import ActionButton from "@/core/components/table/ActionButton.vue";
import FormDatepickerBase from "@/view/content/forms/components/FormDatepickerBase.vue";
import WrapperSuppliersForInvoice from "@/modules/stock/components/actions/stockList/WrapperSuppliersForInvoice.vue";
import WrapperForwardersForInvoice from "@/modules/stock/components/actions/stockList/WrapperForwardersForInvoice.vue";
import WrapperContractorsForInvoice from "@/modules/stock/components/actions/stockList/WrapperContractorsForInvoice.vue";
import WrapperClientsForInvoice from "@/modules/stock/components/actions/stockList/WrapperClientsForInvoice.vue";
import WrapperCustomsForInvoice from "@/modules/stock/components/actions/stockList/WrapperCustomsForInvoice.vue";
//Audit Module
import {
  FETCH_STOCK_ACTIONS,
  FETCH_CLIENTS_WITH_STORES,
  SAVE_STOCK_ACTION_FILTERS
} from "@/modules/stock/store/stock.module";

import { FETCH_STORES_BASIC } from "@/core/services/store/shared_store.module";
import { FETCH_SUPPLIERS_BASIC } from "@/core/services/store/shared_supplier.module";

//Shared Modules
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import i18n from "@/core/plugins/vue-i18n";

import _ from "lodash";
import moment from "moment";
export default {
  name: "StockActionList",
  mixins: [permissionMixin],
  data() {
    return {
      selectedForInvoice: {
        wholesales: [],
        wholesaleReturnings: [],
        suppliers: [],
        returnings: [],
        transports: [],
        addons: [],
        customs: [],
        pallets: []
      },
      firstLoader: true,
      isPageLoading: true,
      items: [],
      stores: [],
      suppliers: [],
      clients: [],
      totalItems: 100,
      allItemsTotalPriceSum: 0,
      searchQuery: "",
      filters: {
        hasInvoiceSupplier: null,
        hasSupplier: null,
        supplierDocNum: "",
        supplierDocDate: null,

        hasInvoiceTransport: null,
        hasTransport: null,
        transportDocNum: "",
        transportDocDate: null,

        hasInvoiceAddon: null,
        hasAddon: null,
        addonDocNum: "",
        addonDocDate: null,

        hasInvoicePallet: null,
        hasPallet: null,
        palletDocNum: "",
        palletDocDate: null,

        hasInvoiceCustoms: null,
        hasCustoms: null,
        customsDocNum: "",
        customsDocDate: null,

        hasInvoiceWholesale: null,
        hasWholesale: null,
        wholesaleDocNum: "",
        wholesaleDocDate: null,

        storeId: null,
        supplierId: null,
        clientId: null,
        fromDate: null,
        toDate: null,
        stockActionTypeId: null,
        echoError: false,
        ajurError: false,
        options: {
          descending: true,
          page: 1,
          itemsPerPage: 10,
          totalItems: 100
        }
      },
      stockActionTypes: [],
      yesNoOptions: [
        {
          value: "1",
          text: i18n.t("YES")
        },
        {
          value: "0",
          text: i18n.t("NO")
        }
      ],
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      cancelSource: null,
      selectedItemId: null,
      headers: [
        {
          text: this.$t("ID"),
          value: "id"
        },
        {
          text: this.$t("AJUR_NUMBER"),
          value: "code"
        },
        {
          text: this.$t("FOR_DATE"),
          value: "forDate"
        },
        {
          text: this.$t("STOCK_ACTION_TYPE_NAME"),
          value: "stockActionTypeName"
        },
        {
          text: this.$t("STORE"),
          value: "storeName",
          sortable: true
        },
        {
          text: this.$t("COUNTERPARTY"),
          value: "supplierName",
          sortable: false
        },
        {
          text: this.$t("DOCS"),
          value: "documents",
          sortable: false
        },
        {
          text: this.$t("TOTAL_VALUE"),
          value: "stockActionTotal",
          align: "right",
          sortable: false
        },
        {
          align: "right",
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ]
    };
  },
  components: {
    SearchTools,
    ActionButton,
    FormDatepickerBase,
    WrapperSuppliersForInvoice,
    WrapperForwardersForInvoice,
    WrapperContractorsForInvoice,
    WrapperClientsForInvoice,
    WrapperCustomsForInvoice
  },
  watch: {
    storesParams(value) {
      let vm = this;
      vm.filters.storeId = null;
      vm.getStores();
    },
    suppliersParams(value) {
      let vm = this;
      vm.filters.supplierId = null;
      vm.getSuppliers();
    },
    clientsParams(value) {
      let vm = this;
      vm.filters.clientId = null;
      vm.getClients();
    },
    filters: {
      handler(newFilters) {
        // Save filters to Vuex when they change
        this.$store.dispatch(SAVE_STOCK_ACTION_FILTERS, newFilters);
      },
      deep: true
    }
  },
  beforeMount() {
    let vm = this;
    if (this.$store.state.stock.stockActionFilters) {
      vm.filters = this.$store.state.stock.stockActionFilters;
    }
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Stock Actions", route: { name: "stock-actions-list" } },
      { title: "Stock Actions List" }
    ]);

    vm.getStores();
    vm.getSuppliers();
    vm.getClients();
    vm.getSelects();
  },
  computed: {
    ...mapGetters([
      "getItems",
      "isLoadingStock",
      "isLoadingSharedStore",
      "currentUser"
    ]),
    params() {
      return {
        ...this.filters.options,
        query: this.searchQuery,

        hasInvoiceSupplier: this.filters.hasInvoiceSupplier,
        hasSupplier: this.filters.hasSupplier,
        supplierDocNum: this.filters.supplierDocNum,
        supplierDocDate: this.filters.supplierDocDate,

        hasInvoiceTransport: this.filters.hasInvoiceTransport,
        hasTransport: this.filters.hasTransport,
        transportDocNum: this.filters.transportDocNum,
        transportDocDate: this.filters.transportDocDate,

        hasInvoiceAddon: this.filters.hasInvoiceAddon,
        hasAddon: this.filters.hasAddon,
        addonDocNum: this.filters.addonDocNum,
        addonDocDate: this.filters.addonDocDate,

        hasInvoicePallet: this.filters.hasInvoicePallet,
        hasPallet: this.filters.hasPallet,
        palletDocNum: this.filters.palletDocNum,
        palletDocDate: this.filters.palletDocDate,

        hasInvoiceCustoms: this.filters.hasInvoiceCustoms,
        hasCustoms: this.filters.hasCustoms,
        customsDocNum: this.filters.customsDocNum,
        customsDocDate: this.filters.customsDocDate,

        hasInvoiceWholesale: this.filters.hasInvoiceWholesale,
        hasWholesale: this.filters.hasWholesale,
        wholesaleDocNum: this.filters.wholesaleDocNum,
        wholesaleDocDate: this.filters.wholesaleDocDate,

        storeId: this.filters.storeId,
        supplierId: this.filters.supplierId,
        clientId: this.filters.clientId,
        fromDate: this.filters.fromDate,
        toDate: this.filters.toDate,
        stockActionTypeId: this.filters.stockActionTypeId,
        echoError: this.filters.echoError,
        ajurError: this.filters.ajurError
      };
    },
    storesParams() {
      let storesParams = {};
      return storesParams;
    },
    suppliersParams() {
      let suppliersParams = {};
      return suppliersParams;
    },
    clientsParams() {
      let clientsParams = {};
      return clientsParams;
    },
    tableHeaders() {
      let vm = this;

      return vm.headers;
    },
    maxDate() {
      return moment().format("YYYY-MM-DD");
    },
    selectedForInvoiceTransformed() {
      const selectedForInvoiceTransformed = {
        suppliers: {},
        returnings: {},
        forwarders: {},
        contractors: {},
        clients: {},
        wholesaleReturnings: {},
        customs: {}
      };

      // Group actions by client
      this.selectedForInvoice.wholesales.forEach(wholesale => {
        if (!selectedForInvoiceTransformed.clients[wholesale.clientId]) {
          selectedForInvoiceTransformed.clients[wholesale.clientId] = {
            clientId: wholesale.clientId,
            clientName: wholesale.clientName,
            wholesales: [],
            wholesaleReturnings: []
          };
        }
        selectedForInvoiceTransformed.clients[
          wholesale.clientId
        ].wholesales.push(wholesale);
      });

      // Group actions by client
      this.selectedForInvoice.wholesaleReturnings.forEach(wholesale => {
        if (
          !selectedForInvoiceTransformed.wholesaleReturnings[wholesale.clientId]
        ) {
          selectedForInvoiceTransformed.wholesaleReturnings[
            wholesale.clientId
          ] = {
            clientId: wholesale.clientId,
            clientName: wholesale.clientName,
            wholesales: [],
            wholesaleReturnings: []
          };
        }
        selectedForInvoiceTransformed.wholesaleReturnings[
          wholesale.clientId
        ].wholesaleReturnings.push(wholesale);
      });

      // Group actions by supplierId
      this.selectedForInvoice.returnings.forEach(supplier => {
        if (!selectedForInvoiceTransformed.returnings[supplier.supplierId]) {
          selectedForInvoiceTransformed.returnings[supplier.supplierId] = {
            supplierId: supplier.supplierId,
            supplierName: supplier.supplierName,
            purchases: [],
            returnings: [],
            pallets: [],
            transports: [],
            addons: []
          };
        }
        selectedForInvoiceTransformed.returnings[
          supplier.supplierId
        ].returnings.push(supplier);
      });

      // Group actions by supplierId
      this.selectedForInvoice.suppliers.forEach(supplier => {
        if (!selectedForInvoiceTransformed.suppliers[supplier.supplierId]) {
          selectedForInvoiceTransformed.suppliers[supplier.supplierId] = {
            supplierId: supplier.supplierId,
            supplierName: supplier.supplierName,
            purchases: [],
            returnings: [],
            pallets: [],
            transports: [],
            addons: []
          };
        }
        selectedForInvoiceTransformed.suppliers[
          supplier.supplierId
        ].purchases.push(supplier);
      });

      // Group pallets by supplierId and add to respective suppliers
      this.selectedForInvoice.pallets.forEach(pallet => {
        if (!selectedForInvoiceTransformed.suppliers[pallet.supplierId]) {
          selectedForInvoiceTransformed.suppliers[pallet.supplierId] = {
            supplierId: pallet.supplierId,
            supplierName: pallet.supplierName,
            purchases: [],
            returnings: [],
            pallets: [],
            transports: [],
            addons: []
          };
        }
        selectedForInvoiceTransformed.suppliers[pallet.supplierId].pallets.push(
          pallet
        );
      });

      // Group addons by supplierId and add to respective suppliers
      this.selectedForInvoice.addons.forEach(addon => {
        if (addon.contractorId == null && addon.supplierId != null) {
          if (!selectedForInvoiceTransformed.suppliers[addon.supplierId]) {
            selectedForInvoiceTransformed.suppliers[addon.supplierId] = {
              supplierId: addon.supplierId,
              supplierName: addon.supplierName,
              purchases: [],
              returnings: [],
              pallets: [],
              transports: [],
              addons: []
            };
          }
          selectedForInvoiceTransformed.suppliers[addon.supplierId].addons.push(
            addon
          );
        }

        if (addon.contractorId != null) {
          if (!selectedForInvoiceTransformed.contractors[addon.contractorId]) {
            selectedForInvoiceTransformed.contractors[addon.contractorId] = {
              contractorId: addon.contractorId,
              contractorName: addon.contractorName,
              addons: []
            };
          }
          selectedForInvoiceTransformed.contractors[
            addon.contractorId
          ].addons.push(addon);
        }
      });

      // Group actions by customs
      this.selectedForInvoice.customs.forEach(customs => {
        if (!selectedForInvoiceTransformed.customs[customs.customsId]) {
          selectedForInvoiceTransformed.customs[customs.customsId] = {
            customsId: customs.customsId,
            customsName: customs.customsName,
            customsActions: []
          };
        }
        selectedForInvoiceTransformed.customs[
          customs.customsId
        ].customsActions.push(customs);
      });

      // Group transports by supplierId and add to respective suppliers
      this.selectedForInvoice.transports.forEach(transport => {
        if (transport.forwarderId == null && transport.supplierId != null) {
          if (!selectedForInvoiceTransformed.suppliers[transport.supplierId]) {
            selectedForInvoiceTransformed.suppliers[transport.supplierId] = {
              supplierId: transport.supplierId,
              supplierName: transport.supplierName,
              purchases: [],
              pallets: [],
              transports: []
            };
          }
          selectedForInvoiceTransformed.suppliers[
            transport.supplierId
          ].transports.push(transport);
        }

        if (transport.forwarderId != null) {
          if (
            !selectedForInvoiceTransformed.forwarders[transport.forwarderId]
          ) {
            selectedForInvoiceTransformed.forwarders[transport.forwarderId] = {
              forwarderId: transport.forwarderId,
              forwarderName: transport.forwarderName,
              transports: []
            };
          }
          selectedForInvoiceTransformed.forwarders[
            transport.forwarderId
          ].transports.push(transport);
        }
      });

      return selectedForInvoiceTransformed;
    }
  },
  methods: {
    clearFilter(key) {
      let vm = this;
      vm.$nextTick(function() {
        this.$set(vm.filters, key, null);
      });
    },
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      let vm = this;
      if (vm.filters.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.filters.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";

      vm.filters.hasSupplier = null;
      vm.filters.hasInvoiceSupplier = null;
      vm.filters.supplierDocNum = "";
      vm.filters.supplierDocDate = null;

      vm.filters.hasTransport = null;
      vm.filters.hasInvoiceTransport = null;
      vm.filters.transportDocNum = "";
      vm.filters.transportDocDate = null;

      vm.filters.hasAddon = null;
      vm.filters.hasInvoiceAddon = null;
      vm.filters.addonDocNum = "";
      vm.filters.addonDocDate = null;

      vm.filters.hasPallet = null;
      vm.filters.hasInvoicePallet = null;
      vm.filters.palletDocNum = "";
      vm.filters.palletDocDate = null;

      vm.filters.hasCustoms = null;
      vm.filters.hasInvoiceCustoms = null;
      vm.filters.customsDocNum = "";
      vm.filters.customsDocDate = null;

      vm.filters.hasWholesale = null;
      vm.filters.hasInvoiceWholesale = null;
      vm.filters.wholesaleDocNum = "";
      vm.filters.wholesaleDocDate = null;

      vm.filters.storeId = null;
      vm.filters.supplierId = null;
      vm.filters.clientId = null;
      vm.filters.stockActionTypeId = null;
      if (vm.filters.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.filters.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      //copy current params to modify
      let params = this.params;
      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      // console.log(apiParams);
      vm.$store
        .dispatch(FETCH_STOCK_ACTIONS, apiParams)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.items = []; //Ако това странно действие не се направи, чекбоксовете за selectedForInvoice, се чекват всичките и става няква аномалия, дето не можах да разбера защо (Анжел - 11.05.2023)
          vm.$nextTick(function() {
            vm.allItemsTotalPriceSum = 0;
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;
            if (_.size(data.data.additionalData) > 0) {
              vm.allItemsTotalPriceSum =
                data.data.additionalData.allItemsTotalPriceSum;
            }

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "stock-actions-list" });
          }
        });
    },
    getStores() {
      let vm = this;
      let params = this.storesParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_STORES_BASIC, apiParams)
        .then(data => {
          this.$set(vm, "stores", data.data);
        })
        .catch(response => {
          console.log(response);
        });
    },
    getSuppliers() {
      let vm = this;
      let params = this.suppliersParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_SUPPLIERS_BASIC, apiParams)
        .then(data => {
          this.$set(vm, "suppliers", data.data);
        })
        .catch(response => {
          console.log(response);
        });
    },
    getClients() {
      let vm = this;
      let params = this.clientsParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_CLIENTS_WITH_STORES, apiParams)
        .then(data => {
          this.$set(vm, "clients", data.data);
        })
        .catch(response => {
          console.log(response);
        });
    },
    getSelects() {
      let vm = this;
      let payload = {
        stock_action_types: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.stockActionTypes = data.data.stock_action_types;
        })
        .catch(response => {
          console.log(response);
        });
    },
    getIconOperator(operator) {
      if (operator == "+") {
        return "flaticon2-plus text-success";
      }

      if (operator == "-") {
        return "flaticon2-line text-danger";
      }
      return "";
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    exportStockRedirectingXls(data) {
      console.log(data);
      console.log(this.stockActionTypeId);
    },
    addedToInvoiceClient(args) {
      let vm = this;
      let clientId = args.clientId;
      let invoiceId = args.invoiceId;
      // Loop through the array in reverse to avoid index shifting
      for (let i = vm.selectedForInvoice.wholesales.length - 1; i >= 0; i--) {
        if (vm.selectedForInvoice.wholesales[i].clientId === clientId) {
          // Use splice to remove the object from the array
          vm.selectedForInvoice.wholesales.splice(i, 1);
        }
      }
      vm.$router.push({
        name: "stock-invoice-review",
        params: { id: invoiceId }
      });
      // this.doSearch();
    },
    addedToInvoiceClientReturning(args) {
      let vm = this;
      let clientId = args.clientId;
      let invoiceId = args.invoiceId;
      // Loop through the array in reverse to avoid index shifting
      for (
        let i = vm.selectedForInvoice.wholesaleReturnings.length - 1;
        i >= 0;
        i--
      ) {
        if (
          vm.selectedForInvoice.wholesaleReturnings[i].clientId === clientId
        ) {
          // Use splice to remove the object from the array
          vm.selectedForInvoice.wholesaleReturnings.splice(i, 1);
        }
      }
      vm.$router.push({
        name: "stock-invoice-review",
        params: { id: invoiceId }
      });
      // this.doSearch();
    },
    addedToInvoiceSupplier(args) {
      let vm = this;
      let supplierId = args.supplierId;
      let invoiceId = args.invoiceId;
      // Loop through the array in reverse to avoid index shifting
      for (let i = vm.selectedForInvoice.suppliers.length - 1; i >= 0; i--) {
        if (vm.selectedForInvoice.suppliers[i].supplierId === supplierId) {
          // Use splice to remove the object from the array
          vm.selectedForInvoice.suppliers.splice(i, 1);
        }
      }

      // Loop through the array in reverse to avoid index shifting
      for (let i = vm.selectedForInvoice.pallets.length - 1; i >= 0; i--) {
        if (vm.selectedForInvoice.pallets[i].supplierId === supplierId) {
          // Use splice to remove the object from the array
          vm.selectedForInvoice.pallets.splice(i, 1);
        }
      }

      //За транспорта е с допълнителна проверка да няма forwarderId
      // Loop through the array in reverse to avoid index shifting
      for (let i = vm.selectedForInvoice.transports.length - 1; i >= 0; i--) {
        if (
          vm.selectedForInvoice.transports[i].supplierId === supplierId &&
          vm.selectedForInvoice.transports[i].forwarderId == null
        ) {
          // Use splice to remove the object from the array
          vm.selectedForInvoice.transports.splice(i, 1);
        }
      }

      //За транспорта е с допълнителна проверка да няма contractorId
      // Loop through the array in reverse to avoid index shifting
      for (let i = vm.selectedForInvoice.addons.length - 1; i >= 0; i--) {
        if (
          vm.selectedForInvoice.addons[i].supplierId === supplierId &&
          vm.selectedForInvoice.addons[i].contractorId == null
        ) {
          // Use splice to remove the object from the array
          vm.selectedForInvoice.addons.splice(i, 1);
        }
      }
      vm.$router.push({
        name: "stock-invoice-review",
        params: { id: invoiceId }
      });
      // this.doSearch();
    },
    addedToInvoiceReturning(args) {
      let vm = this;
      let supplierId = args.supplierId;
      let invoiceId = args.invoiceId;
      // Loop through the array in reverse to avoid index shifting
      for (let i = vm.selectedForInvoice.returnings.length - 1; i >= 0; i--) {
        if (vm.selectedForInvoice.returnings[i].supplierId === supplierId) {
          // Use splice to remove the object from the array
          vm.selectedForInvoice.returnings.splice(i, 1);
        }
      }

      // Loop through the array in reverse to avoid index shifting
      for (let i = vm.selectedForInvoice.pallets.length - 1; i >= 0; i--) {
        if (vm.selectedForInvoice.pallets[i].supplierId === supplierId) {
          // Use splice to remove the object from the array
          vm.selectedForInvoice.pallets.splice(i, 1);
        }
      }

      //За транспорта е с допълнителна проверка да няма forwarderId
      // Loop through the array in reverse to avoid index shifting
      for (let i = vm.selectedForInvoice.transports.length - 1; i >= 0; i--) {
        if (
          vm.selectedForInvoice.transports[i].supplierId === supplierId &&
          vm.selectedForInvoice.transports[i].forwarderId == null
        ) {
          // Use splice to remove the object from the array
          vm.selectedForInvoice.transports.splice(i, 1);
        }
      }

      //За транспорта е с допълнителна проверка да няма contractorId
      // Loop through the array in reverse to avoid index shifting
      for (let i = vm.selectedForInvoice.addons.length - 1; i >= 0; i--) {
        if (
          vm.selectedForInvoice.addons[i].supplierId === supplierId &&
          vm.selectedForInvoice.addons[i].contractorId == null
        ) {
          // Use splice to remove the object from the array
          vm.selectedForInvoice.addons.splice(i, 1);
        }
      }
      vm.$router.push({
        name: "stock-invoice-review",
        params: { id: invoiceId }
      });
      // this.doSearch();
    },
    addedToInvoiceForwarder(args) {
      let vm = this;
      let forwarderId = args.forwarderId;
      let invoiceId = args.invoiceId;
      // Loop through the array in reverse to avoid index shifting
      for (let i = vm.selectedForInvoice.transports.length - 1; i >= 0; i--) {
        if (vm.selectedForInvoice.transports[i].forwarderId === forwarderId) {
          // Use splice to remove the object from the array
          vm.selectedForInvoice.transports.splice(i, 1);
        }
      }
      vm.$router.push({
        name: "stock-invoice-review",
        params: { id: invoiceId }
      });
      // this.doSearch();
    },
    addedToInvoiceContractor(args) {
      let vm = this;
      let contractorId = args.contractorId;
      let invoiceId = args.invoiceId;
      // Loop through the array in reverse to avoid index shifting
      for (let i = vm.selectedForInvoice.addons.length - 1; i >= 0; i--) {
        if (vm.selectedForInvoice.addons[i].contractorId === contractorId) {
          // Use splice to remove the object from the array
          vm.selectedForInvoice.addons.splice(i, 1);
        }
      }
      vm.$router.push({
        name: "stock-invoice-review",
        params: { id: invoiceId }
      });
      // this.doSearch();
    },
    addedToInvoiceCustoms(args) {
      let vm = this;
      let customsId = args.customsId;
      let invoiceId = args.invoiceId;
      // Loop through the array in reverse to avoid index shifting
      for (let i = vm.selectedForInvoice.customs.length - 1; i >= 0; i--) {
        if (vm.selectedForInvoice.customs[i].customsId === customsId) {
          // Use splice to remove the object from the array
          vm.selectedForInvoice.customs.splice(i, 1);
        }
      }
      vm.$router.push({
        name: "stock-invoice-review",
        params: { id: invoiceId }
      });
      // this.doSearch();
    }
  }
};
</script>
