<template>
  <div>
    <v-col
      ><h3>{{ header }}</h3></v-col
    >
    <v-data-table
      :headers="headers"
      :items="actions"
      item-key="name"
      class="elevation-1"
      :search="search"
      hide-default-footer
    >
      <template v-slot:item.stockDocData.docDate="{ item }">
        {{ item.stockDocData.docDate | formatDate }}
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt }}
      </template>

      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Търси"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:body.append>
        <tr>
          <td colspan="4"></td>
          <td class="text-right">
            <strong
              >{{ $t("TOTAL_WITH_VAT_CURRENCY") }}:
              {{ sumDocTotalWithVat(actions) }}</strong
            >
          </td>
          <td></td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  name: "TableActionsForInvoice",
  inheritAttrs: false,
  props: {
    actions: {
      type: Array,
      default: function() {
        return [];
      }
    },
    header: {
      type: String,
      default: function() {
        return "Actions";
      }
    }
  },
  data() {
    return {
      search: "",
      docTotalWithVatCurrency: ""
    };
  },
  components: {},
  computed: {
    headers() {
      return [
        {
          text: this.$t("DOC_ID"),
          align: "start",
          value: "stockDocData.id"
        },
        {
          text: this.$t("DOC_NUM"),
          value: "stockDocData.docNum"
        },
        {
          text: this.$t("DOC_DATE"),
          value: "stockDocData.docDate"
        },
        {
          text: this.$t("DATE"),
          value: "createdAt"
        },
        {
          text: this.$t("DOC_TOTAL_WITH_VAT_CURRENCY"),
          align: "end",
          value: "stockDocData.docTotalWithVatCurrency",
          filter: value => {
            if (!this.docTotalWithVatCurrency) return true;

            return value < parseInt(this.docTotalWithVatCurrency);
          }
        },
        {
          text: this.$t("CURRENCY_CODE"),
          value: "stockDocData.currencyCode"
        }
      ];
    }
  },
  methods: {
    sumDocTotalWithVat(purchases) {
      return this.$options.filters.formatBalance(
        purchases.reduce(
          (total, item) =>
            parseFloat(total) +
            parseFloat(item.stockDocData.docTotalWithVatCurrency),
          0
        )
      );
    }
  }
};
</script>
