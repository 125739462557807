<template>
  <div>
    <h4>{{ header }}</h4>
    <v-list
      v-if="
        source == 'supplier' ||
          source == 'client' ||
          source == 'customs' ||
          source == 'credit-debit'
      "
      three-line
      subheader
    >
      <v-list-item>
        <v-list-item-content>
          <ModalInvoiceAddItems :actions="actions"></ModalInvoiceAddItems>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list three-line subheader>
      <v-list-item>
        <v-list-item-content>
          <ModalInvoiceAddDocuments
            :actions="actions"
          ></ModalInvoiceAddDocuments>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import _ from "lodash";

import ModalInvoiceAddItems from "@/modules/stock/components/actions/stockList/ModalInvoiceAddItems.vue";
import ModalInvoiceAddDocuments from "@/modules/stock/components/actions/stockList/ModalInvoiceAddDocuments.vue";

export default {
  name: "ModalAddToInvoiceSummaryList",
  inheritAttrs: false,
  props: {
    actions: {
      type: Array,
      default: function() {
        return [];
      }
    },
    source: {
      type: String,
      default: function() {
        return "supplier";
      }
    },
    header: {
      type: String,
      default: function() {
        return "Actions";
      }
    }
  },
  data() {
    return {};
  },
  components: {
    ModalInvoiceAddItems,
    ModalInvoiceAddDocuments
  },
  computed: {},
  methods: {}
};
</script>
