<template>
  <v-row class="mr-5" justify="end">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on">
          <template v-if="source == 'customs'">{{
            $t("ADD_TO_CUSTOMS_DOCUMENT")
          }}</template>
          <template v-else>{{ $t("ADD_TO_INVOICE") }}</template>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="source == 'customs'"
            >{{ $t("CUSTOMS_DOCUMENT") }}
          </v-toolbar-title>
          <v-toolbar-title v-else>{{ $t("INVOICE") }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <template
          v-if="
            _.size(stockCreditDebitAction) > 0 ||
              _.size(supplier.purchases) > 0 ||
              _.size(supplier.returnings) > 0 ||
              _.size(supplier.pallets) > 0 ||
              _.size(supplier.addons) > 0 ||
              _.size(supplier.transports) > 0 ||
              _.size(forwarder.transports) > 0 ||
              _.size(contractor.addons) > 0 ||
              _.size(customs.customsActions) > 0 ||
              _.size(client.wholesales) > 0 ||
              _.size(client.wholesaleReturnings) > 0
          "
        >
          <v-col sm="12">
            <ModalAddToInvoiceSummaryList
              v-if="_.size(stockCreditDebitAction) > 0"
              :actions="[stockCreditDebitAction]"
              :source="'credit-debit'"
              :header="
                stockCreditDebitAction.typeName +
                  ' - ' +
                  stockCreditDebitAction.counterpartyName
              "
            ></ModalAddToInvoiceSummaryList>
            <ModalAddToInvoiceSummaryList
              v-if="_.size(supplier.purchases) > 0"
              :actions="supplier.purchases"
              :source="'supplier'"
              :header="$t('PURCHASES') + ' - ' + supplier.supplierName"
            ></ModalAddToInvoiceSummaryList>
            <ModalAddToInvoiceSummaryList
              v-if="_.size(supplier.returnings) > 0"
              :actions="supplier.returnings"
              :source="'supplier'"
              :header="$t('RETURNINGS') + ' - ' + supplier.supplierName"
            ></ModalAddToInvoiceSummaryList>
            <ModalAddToInvoiceSummaryList
              v-if="_.size(supplier.pallets) > 0"
              :actions="supplier.pallets"
              :source="'pallet'"
              :header="$t('PALLETS') + ' - ' + supplier.supplierName"
            ></ModalAddToInvoiceSummaryList>
            <ModalAddToInvoiceSummaryList
              v-if="_.size(supplier.addons) > 0"
              :actions="supplier.addons"
              :source="'addon'"
              :header="$t('ADDONS') + ' - ' + supplier.supplierName"
            ></ModalAddToInvoiceSummaryList>
            <ModalAddToInvoiceSummaryList
              v-if="_.size(supplier.transports) > 0"
              :actions="supplier.transports"
              :source="'transport'"
              :header="$t('TRANSPORTS') + ' - ' + supplier.supplierName"
            ></ModalAddToInvoiceSummaryList>
            <ModalAddToInvoiceSummaryList
              v-if="_.size(forwarder.transports) > 0"
              :actions="forwarder.transports"
              :source="'transport'"
              :header="$t('TRANSPORTS') + ' - ' + forwarder.forwarderName"
            ></ModalAddToInvoiceSummaryList>
            <ModalAddToInvoiceSummaryList
              v-if="_.size(contractor.addons) > 0"
              :actions="contractor.addons"
              :source="'addon'"
              :header="$t('ADDON') + ' - ' + contractor.contractorName"
            ></ModalAddToInvoiceSummaryList>
            <ModalAddToInvoiceSummaryList
              v-if="_.size(customs.customsActions) > 0"
              :actions="customs.customsActions"
              :source="'customs'"
              :header="$t('CUSTOMS') + ' - ' + customs.customsName"
            ></ModalAddToInvoiceSummaryList>
            <ModalAddToInvoiceSummaryList
              v-if="_.size(client.wholesales) > 0"
              :actions="client.wholesales"
              :source="'client'"
              :header="$t('WHOLESALES') + ' - ' + client.clientName"
            ></ModalAddToInvoiceSummaryList>
            <ModalAddToInvoiceSummaryList
              v-if="_.size(client.wholesaleReturnings) > 0"
              :actions="client.wholesaleReturnings"
              :source="'client '"
              :header="$t('STOCK_CLIENT_RETURNING') + ' - ' + client.clientName"
            ></ModalAddToInvoiceSummaryList>

            <v-btn
              v-if="selectedInvoiceId != null"
              @click="addToInvoice()"
              color="success"
              x-large
            >
              {{ $t("ADD_TO_INVOICE") }}
            </v-btn>
            <v-btn
              class="ml-5"
              v-if="selectedInvoiceId != null"
              @click="selectedInvoice(null)"
            >
              {{ $t("CLOSE_INVOICE") }}
            </v-btn>
          </v-col>
          <v-col sm="12" v-show="selectedInvoiceId == null">
            <ModalInvoiceSelect
              :params="dynamicParams"
              :type="type"
              :source="source"
              @selectedInvoice="selectedInvoice"
            ></ModalInvoiceSelect>
          </v-col>
        </template>
        <v-col sm="12">
          <StockInvoiceShow
            v-if="selectedInvoiceId != null"
            :isPageLoading.sync="isPageLoading"
            :firstLoader.sync="firstLoader"
            :selectedInvoiceId="selectedInvoiceId"
          ></StockInvoiceShow>
        </v-col>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import _ from "lodash";

import ModalAddToInvoiceSummaryList from "@/modules/stock/components/actions/stockList/ModalAddToInvoiceSummaryList.vue";
import ModalInvoiceSelect from "@/modules/stock/components/actions/stockList/ModalInvoiceSelect.vue";
import StockInvoiceShow from "@/modules/stock/components/actions/stockList/invoice/StockInvoiceShow.vue";

import { ADD_ITEMS_TO_INVOICE } from "@/modules/stock/store/stock.module";
export default {
  name: "ModalAddToInvoice",
  inheritAttrs: false,
  props: {
    supplier: {
      type: Object,
      default: function() {
        return {};
      }
    },
    forwarder: {
      type: Object,
      default: function() {
        return {};
      }
    },
    contractor: {
      type: Object,
      default: function() {
        return {};
      }
    },
    customs: {
      type: Object,
      default: function() {
        return {};
      }
    },
    client: {
      type: Object,
      default: function() {
        return {};
      }
    },
    actions: {
      type: Array,
      default: function() {
        return [];
      }
    },
    stockCreditDebitAction: {
      type: Array,
      default: function() {
        return [];
      }
    },
    type: {
      type: String,
      default: function() {
        return "incoming";
      }
    },
    documentType: {
      type: String,
      default: function() {
        return "invoice";
      }
    },
    source: {
      type: String,
      default: function() {
        return "supplier";
      }
    }
  },
  data() {
    return {
      firstLoader: true,
      isPageLoading: true,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      selectedInvoiceId: null
    };
  },
  components: {
    ModalAddToInvoiceSummaryList,
    ModalInvoiceSelect,
    StockInvoiceShow
  },
  computed: {
    dynamicParams() {
      let vm = this;
      let dynamicParams = {};
      let tempObject = {};
      if (vm.source == "supplier") {
        tempObject = Object.assign({}, vm.supplier);
        console.log(tempObject);
        // Extracting IDs from the 'pallets' array
        const palletsIds = tempObject.pallets.map(pallet => pallet.id);

        // Extracting IDs from the 'purchases' array
        const purchasesIds = tempObject.purchases.map(purchase => purchase.id);

        // Extracting IDs from the 'returnings' array
        const returningsIds = tempObject.returnings.map(
          returning => returning.id
        );

        // Extracting IDs from the 'transports' array
        const transportsIds = tempObject.transports.map(
          transport => transport.id
        );
        // Extracting IDs from the 'transports' array
        const addonsIds = tempObject.addons.map(addon => addon.id);

        dynamicParams = {
          type: vm.type,
          documentType: vm.documentType,
          source: vm.source,
          supplierId: tempObject.supplierId,
          palletsIds: palletsIds,
          purchasesIds: purchasesIds,
          returningsIds: returningsIds,
          transportsIds: transportsIds,
          addonsIds: addonsIds
        };
      } else if (vm.source == "forwarder") {
        tempObject = Object.assign({}, vm.forwarder);

        // Extracting IDs from the 'transports' array
        const transportsIds = tempObject.transports.map(
          transport => transport.id
        );

        dynamicParams = {
          type: vm.type,
          documentType: vm.documentType,
          source: vm.source,
          forwarderId: tempObject.forwarderId,
          transportsIds: transportsIds
        };
      } else if (vm.source == "contractor") {
        tempObject = Object.assign({}, vm.contractor);

        // Extracting IDs from the 'transports' array
        const addonsIds = tempObject.addons.map(transport => transport.id);

        dynamicParams = {
          type: vm.type,
          documentType: vm.documentType,
          source: vm.source,
          contractorId: tempObject.contractorId,
          addonsIds: addonsIds
        };
      } else if (vm.source == "customs") {
        tempObject = Object.assign({}, vm.customs);

        // Extracting IDs from the 'customsActions' array
        const customsActionsIds = tempObject.customsActions.map(
          customsAction => customsAction.id
        );

        dynamicParams = {
          type: vm.type,
          documentType: vm.documentType,
          source: vm.source,
          customsId: tempObject.customsId,
          customsActionsIds: customsActionsIds
        };
      } else if (vm.source == "client") {
        tempObject = Object.assign({}, vm.client);

        // Extracting IDs from the 'transports' array
        const wholesalesIds = tempObject.wholesales.map(
          wholesale => wholesale.id
        );
        // Extracting IDs from the 'transports' array
        const wholesaleReturningsIds = tempObject.wholesaleReturnings.map(
          wholesaleReturning => wholesaleReturning.id
        );

        dynamicParams = {
          type: vm.type,
          documentType: vm.documentType,
          source: vm.source,
          clientId: tempObject.clientId,
          wholesalesIds: wholesalesIds,
          wholesaleReturningsIds: wholesaleReturningsIds
        };
      } else if (vm.source == "credit-debit") {
        tempObject = Object.assign({}, vm.stockCreditDebitAction);

        dynamicParams = {
          type: vm.type,
          documentType: vm.documentType,
          source: vm.source,
          stockCreditDebitActionId: tempObject.id
        };
      }
      return dynamicParams;
    }
  },
  methods: {
    selectedInvoice(invoiceId) {
      this.selectedInvoiceId = invoiceId;
    },
    addToInvoice() {
      let vm = this;
      const payload = {
        invoiceId: vm.selectedInvoiceId,
        source: vm.source,
        params: vm.dynamicParams
      };
      this.$store
        .dispatch(ADD_ITEMS_TO_INVOICE, payload)
        .then(data => {
          vm.errors = data.data.errors;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$emit("addedToInvoice", { invoiceId: vm.selectedInvoiceId });
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    }
  }
};
</script>
