<template>
  <v-data-table
    :headers="headers"
    :items="actionsArray"
    item-key="name"
    class="elevation-1"
    :search="search"
    hide-default-footer
  >
    <template v-slot:body.append>
      <tr>
        <td colspan="2">
          <strong>{{ $t("TOTAL") }}</strong>
        </td>
        <td class="text-right">
          <strong>{{ documentsTotal.docTotalCurrency | formatBalance }}</strong>
        </td>

        <td class="text-right">
          <strong>{{ documentsTotal.docVatCurrency | formatBalance }}</strong>
        </td>

        <td class="text-right">
          <strong>{{
            documentsTotal.docTotalWithVatCurrency | formatBalance
          }}</strong>
        </td>

        <td><strong>-</strong></td>
        <td class="text-right"><strong>-</strong></td>

        <td class="text-right">
          <strong>{{ documentsTotal.docTotal | formatBalance }}</strong>
        </td>

        <td class="text-right">
          <strong>{{ documentsTotal.docVat | formatBalance }}</strong>
        </td>

        <td class="text-right">
          <strong>{{ documentsTotal.docTotalWithVat | formatBalance }}</strong>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
import _ from "lodash";
export default {
  name: "ModalInvoiceAddDocuments",
  inheritAttrs: false,
  props: {
    actions: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  mounted() {},
  data() {
    return {};
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("DOC_NUM"),
          align: "start",
          value: "docNum"
        },
        {
          text: this.$t("DOC_DATE"),
          align: "start",
          value: "docDate"
        },
        {
          text: this.$t("TOTAL_WITHOUT_VAT_CURRENCY"),
          align: "end",
          value: "docTotalCurrency"
        },
        {
          text: this.$t("TOTAL_VAT_CURRENCY"),
          align: "end",
          value: "docVatCurrency"
        },
        {
          text: this.$t("TOTAL_WITH_VAT_CURRENCY"),
          align: "end",
          value: "docTotalWithVatCurrency"
        },
        {
          text: this.$t("CURRENCY_CODE"),
          align: "start",
          value: "currencyCode"
        },
        {
          text: this.$t("EXCHANGE_RATE"),
          align: "end",
          value: "docCurrencyExchangeRate"
        },
        {
          text: this.$t("TOTAL_WITHOUT_VAT"),
          align: "end",
          value: "docTotal"
        },
        {
          text: this.$t("TOTAL_VAT"),
          align: "end",
          value: "docVat"
        },
        {
          text: this.$t("TOTAL_WITH_VAT"),
          align: "end",
          value: "docTotalWithVat"
        }
      ];
    },
    actionsArray() {
      // Example array of objects
      let vm = this;
      const originalArray = vm.actions;

      // Extract the stockDocData values from each object and merge into a new array of objects
      const newArray = originalArray.map(obj =>
        Object.assign({}, obj.stockDocData)
      );

      // Log the new array to the console
      return newArray;
    },
    documentsTotal() {
      let vm = this;
      // Compute the sum of each key in the array
      const documentsTotal = vm.actionsArray.reduce((acc, curr) => {
        acc.docTotal = (acc.docTotal || 0) + parseFloat(curr.docTotal);
        acc.docVat = (acc.docVat || 0) + parseFloat(curr.docVat);
        acc.docTotalWithVat =
          (acc.docTotalWithVat || 0) + parseFloat(curr.docTotalWithVat);

        acc.docTotalCurrency =
          (acc.docTotalCurrency || 0) + parseFloat(curr.docTotalCurrency);
        acc.docVatCurrency =
          (acc.docVatCurrency || 0) + parseFloat(curr.docVatCurrency);
        acc.docTotalWithVatCurrency =
          (acc.docTotalWithVatCurrency || 0) +
          parseFloat(curr.docTotalWithVatCurrency);
        return acc;
      }, {});
      return documentsTotal;
    }
  }
};
</script>
