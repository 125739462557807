<template>
  <div>
    <StockInvoiceListTable
      :isPageLoading.sync="isPageLoading"
      :firstLoader.sync="firstLoader"
      :isPreviewMode="true"
      @openInvoice="openInvoice"
    ></StockInvoiceListTable>
  </div>
</template>
<script>
import _ from "lodash";
import StockInvoiceListTable from "@/modules/stock/components/actions/stockList/invoice/StockInvoiceListTable.vue";
export default {
  name: "ModalInvoiceExisting",
  inheritAttrs: false,
  props: {
    source: {
      type: String,
      default: function() {
        return "supplier";
      }
    }
  },
  data() {
    return {
      firstLoader: true,
      isPageLoading: true,
      selectedExistingInvoiceId: null
    };
  },
  components: {
    StockInvoiceListTable
  },
  computed: {},
  methods: {
    openInvoice(invoiceId) {
      this.selectedExistingInvoiceId = invoiceId;
      this.$emit("selectedInvoice", invoiceId);
    }
  }
};
</script>
